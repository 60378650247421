import {
    faArrowUpRightFromSquare as farArrowUpRightFromSquare,
    faArrowLeft as farArrowLeft,
    faArrowRight as farArrowRight,
    faBars as farBars,
    faList as farList,
    faPhone as farPhone,
    faTimes as farTimes,
} from '@fortawesome/pro-regular-svg-icons'

import {
    faFacebook as farFacebook,
    faXTwitter as farXTwitter,
    faLinkedin as farLinkedin,
    faInstagram as farInstagram,
} from '@fortawesome/free-brands-svg-icons'

export const icons = {
    farArrowUpRightFromSquare,
    farArrowLeft,
    farArrowRight,
    farBars,
    farList,
    farPhone,
    farTimes,
    farFacebook,
    farXTwitter,
    farLinkedin,
    farInstagram
}