import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'

/**
 *
 * Sentry
 *
 */
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://3cdbbae15f445ef4c114d5763446b802@o4508358341033984.ingest.de.sentry.io/4508399491678288",
    environment: process.env.NODE_ENV || 'development',
    integrations: [
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: "light",
            autoInject: window.showFeedback ?? false,
        }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
        'Non-Error promise rejection captured'
    ]
});

/**
 * Font Awesome
*/
import { icons } from './icons.js'
import { library, dom } from '@fortawesome/fontawesome-svg-core'

library.add(icons);

dom.watch();

/**
 * Alpine.js
 */
window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()